<template>
  <div>
    <!-- <img
      style="
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        display: block;
        width: 100%;
        z-index: -1;
      "
      src="../assets/image/homepage.jpg"
    /> -->
    <headPage />
    <!-- 置换新风系统 -->
    <div style="position: relative; height: 707px; overflow: hidden">
      <img
        style="display: block; width: 100%; transform: translate(0px, -52px)"
        src="../assets/image/windSystem/image1.png"
      />
      <div class="info">
        <div class="title1">清新空气 畅快呼吸</div>
        <div class="title1-1">置换新风系统</div>
      </div>
    </div>
    <!-- 新风湖 -->
    <div style="position: relative; overflow: hidden; height: 581px">
      <img
        style="display: block; width: 111%; transform: translate(-56px, -6px)"
        src="../assets/image/windSystem/image2.png"
      />
      <div class="info2">
        <div data-aos="fade-right">
          <div class="title2">「 新风湖 」</div>
          <div class="title2-1">
            地送顶回，设置在地面上的新风口送入经过除尘、温度及湿度处理、增负离子处理的室外新鲜空气，送风口风速很小，风速≤0.3m/s，将风由地面缓缓送入室内,蔓延形成“新风湖”。
          </div>
        </div>
        <div class="title2-2">
          <div class="line"></div>
          <p class="p1">>1 <span>次</span></p>
          <p class="p2">保证每小时置换空气次数</p>
        </div>
        <div class="title2-3">
          <div class="line"></div>
          <p class="p1">< 700<span>ppm</span></p>
          <p class="p2">CO2浓度控制</p>
        </div>
      </div>
    </div>
    <!-- 出风口 -->
    <div style="position: relative; overflow: hidden; height: 795px">
      <img
        style="display: block; width: 100%; transform: translate(0px, -18px)"
        src="../assets/image/windSystem/image3.png"
      />
      <div class="info3">
        <div data-aos="fade-right">
          <div class="title3-1">细长型</div>
          <div class="title3-2">出风口</div>
          <div class="line2"></div>
        </div>
        <div class="title3-3">AIR</div>
        <div class="title3-4">OUTLET</div>
        <img
          class="img1"
          style="display: block; width: 42%"
          src="../assets/image/windSystem/image4.png"
        />
        <img
          class="img2"
          style="display: block; width: 42%"
          src="../assets/image/windSystem/image5.png"
        />
        <img
          class="img3"
          style="display: block; width: 20%"
          src="../assets/image/windSystem/image6.png"
        />
      </div>
    </div>
    <footPage />
  </div>
</template>
<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
export default {
  methods: {
    jump(url) {
      this.$router.push(url);
    },
  },
  components: {
    headPage,
    footPage,
  },
};
</script>
<style lang="scss" scoped>
.img1 {
  position: absolute;
  left: 142px;
  top: 397px;
}
.img2 {
  position: absolute;
  left: 741px;
  top: 30px;
}
.img3 {
  position: absolute;
  left: 851px;
  top: 431px;
}
.info {
  width: 100%;
  height: 750px;
  position: absolute;
  top: 0;
}
.title1 {
  color: black;
  font-family: "SourceHanSansCN-Medium";
  font-size: 62px;
  margin-top: 227px;
}
.title1-1 {
  color: black;
  font-family: "SourceHanSansCN-Medium";
  font-size: 45px;
  margin-top: -10px;
}
.title2 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 63px;
  color: #d0ac32;
  margin-top: 71px;
  margin-left: 163px;
}
.title2-1 {
  color: #3f3f3f;
  width: 500px;
  text-align: justify;
  margin-top: 4px;
  margin-left: 199px;
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
}
.info2 {
  position: absolute;
  width: 100%;
  height: 588px;
  top: 0;
  text-align: left;
}
.info3 {
  position: absolute;
  width: 100%;
  height: 588px;
  top: 0;
  text-align: left;
}
.line {
  width: 83px;
  height: 3px;
  background-color: #767070;
}
.p1 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 60px;
  color: #d0ac32;
  margin-top: 10px;
}
.p2 {
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
  color: #3f3f3f;
}
.title2-2 {
  position: absolute;
  left: 785px;
  top: 330px;
}
.title2-3 {
  position: absolute;
  right: 230px;
  top: 330px;
}
span {
  font-family: "SourceHanSansCN-Medium";
  font-size: 35px;
  color: #d0ac32;
  margin-left: 1px;
}
.title3-1 {
  font-family: "SourceHanSansCN-Blod";
  font-size: 60px;
  color: #fff;
  position: absolute;
  left: 207px;
  top: 67px;
}
.title3-2 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 62px;
  color: #fff;
  position: absolute;
  left: 205px;
  top: 146px;
}
.line2 {
  width: 144px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  left: 211px;
  top: 264px;
}
.title3-3 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 125px;
  color: #fff;
  position: absolute;
  top: 106px;
  left: -19px;
  opacity: 0.2;
}
.title3-4 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 125px;
  color: #fff;
  position: absolute;
  top: 254px;
  left: -19px;
  opacity: 0.2;
}
</style>